import * as React from "react";
import { min } from "lodash";
import {
  HorizontalGridLines,
  VerticalGridLines,
  LineSeries,
  XYPlot,
  XAxis,
  YAxis,
} from "react-vis";
export default function Graph({ data }) {
  const width = window.innerWidth - 30;
  const height = min([window.innerHeight / 3, 300]);

  return (
    <>
      <div className="graph">
        <h2>Temperature (high vs feels like)</h2>
        <XYPlot xType="time" width={width} height={height}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <LineSeries data={data.temp_hi} />
          <LineSeries data={data.wind_chill_last} />
          <XAxis
            position={"middle"}
            tickFormat={function tickFormat(d) {
              const date = new Date(d);
              return date.toISOString().substring(11, 16);
            }}
          />
          <YAxis />
        </XYPlot>
      </div>
      <div className="graph">
        <h2>Wind speed (high vs average)</h2>
        <XYPlot xType="time" width={width} height={height}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <LineSeries data={data.wind_speed_avg} />
          <LineSeries data={data.wind_speed_hi} />
          <XAxis
            position={"middle"}
            tickFormat={function tickFormat(d) {
              const date = new Date(d);
              return date.toISOString().substring(11, 16);
            }}
          />
          <YAxis />
        </XYPlot>
      </div>
      <div className="graph">
        <h2>Wind direction</h2>
        <XYPlot xType="time" width={width} height={height}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <LineSeries data={data.wind_dir_of_prevail} />
          <XAxis
            position={"middle"}
            tickFormat={function tickFormat(d) {
              const date = new Date(d);
              return date.toISOString().substring(11, 16);
            }}
          />
          <YAxis />
        </XYPlot>
      </div>
      <div className="graph">
        <h2>UV Index</h2>
        <XYPlot xType="time" width={width} height={height}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <LineSeries data={data.uv_index_avg} />
          <XAxis
            position={"middle"}
            tickFormat={function tickFormat(d) {
              const date = new Date(d);
              return date.toISOString().substring(11, 16);
            }}
          />
          <YAxis />
        </XYPlot>
      </div>
      <div className="graph">
        <h2>Pressure</h2>
        <XYPlot xType="time" width={width} height={height}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <LineSeries data={data.pressure_mbar} />
          <XAxis
            position={"middle"}
            tickFormat={function tickFormat(d) {
              const date = new Date(d);
              return date.toISOString().substring(11, 16);
            }}
          />
          <YAxis />
        </XYPlot>
      </div>
      <div className="graph">
        <h2>Rainfall</h2>
        <XYPlot xType="time" width={width} height={height}>
          <HorizontalGridLines />
          <VerticalGridLines />
          <LineSeries data={data.rainfall_mm} />
          <XAxis
            position={"middle"}
            tickFormat={function tickFormat(d) {
              const date = new Date(d);
              return date.toISOString().substring(11, 16);
            }}
          />
          <YAxis />
        </XYPlot>
      </div>
    </>
  );
}
